import Cookies from 'js-cookie';
import _ from 'lodash';
import md5 from 'md5';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { APP_IMG, NEXT_PUBLIC_TIMER_LANDING_PAGE } from 'src/constants';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer.js';

const SectionLandingPage = ({ data = [] }) => {
  if (_.isEmpty(data)) return null;

  const router = useRouter();
  const [visible, setVisible] = useState(false);

  function openCookieAccept() {
    const cookieFirstPage = Cookies.get('first_page');
    if (cookieFirstPage) {
      document.getElementById('landing-page')?.remove();
    }
    setVisible(!cookieFirstPage);
  }

  async function handleAccept() {
    const _id = md5('first_page');
    const _expires = 1 / 48;

    Cookies.set('first_page', _id, { expires: _expires });
    router.reload(window.location.pathname);
  }

  useEffect(() => {
    openCookieAccept();
  }, [visible]);

  useEffect(() => {
    // handleCommemorate
    const getBody = document.querySelector('body');
    // handleCommemorate
    if (visible) {
      // handleCommemorate
      getBody.style.overflow = 'hidden';
      // handleCommemorate
      document.getElementById('__next').classList?.add('show-landing-page');
      document.getElementById('footer-landing')?.setAttribute('style', 'display:flex;');
      document.getElementById('truehits_div')?.setAttribute('style', 'display:none;');
      const timer = setTimeout(() => {
        handleAccept();
      }, (NEXT_PUBLIC_TIMER_LANDING_PAGE || 120000));
      return () => clearTimeout(timer);
    } else {
      // handleCommemorate
      getBody.style.overflow = 'initial';
      // handleCommemorate
      document.getElementById('__next').classList?.remove('show-landing-page');
      document.getElementById('footer-landing')?.setAttribute('style', 'display:none;');
      document.getElementById('truehits_div')?.setAttribute('style', 'display:flex;');
    }
  }, [visible]);
  return (
    <>
      <a href={!data.url ? '#' : data.url} target={!data.url ? '_self' : '_blank'} rel={'noopener noreferrer nofollow'}>
        <SectionWrapper
          id='landing-page'
          className='landing-wrapper'
          dataImage={{ ...data }}
          // onClick={() => handleAccept()}
          onClick={() =>
            data.url &&
            sendDataLayerGlobal({
              type: DATALAYER_TYPE.TRACK_INDEX,
              router: router?.pathname,
              section: 'landingpage',
              data: {
                title: data.title,
                heading: data.title
              }
            })
          }
        />
      </a>
      <FooterWrapper id='footer-landing' className='footer-landing'>
        <button
          id='btn-inside'
          aria-label='inside'
          title='inside'
          onClick={() => {
            data.url &&
              sendDataLayerGlobal({
                type: DATALAYER_TYPE.TRACK_INDEX,
                router: router?.pathname,
                section: 'landingpage',
                data: {
                  title: 'เข้าสู่เว็บไซต์',
                  heading: data.title
                }
              });
            handleAccept();
          }}
        >
          <span> เข้าสู่เว็บไซต์ </span>
        </button>
      </FooterWrapper>
    </>
  );
};
const SectionWrapper = styled.section`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999999999;
  background-image: ${({ dataImage }) => `url(${APP_IMG}/${dataImage?.imagePath})`};
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: rgba(256, 256, 256, 1);

  @media (min-width: 1044px) {
    background-size: cover;
  }
  @media (max-width: 767px) {
    background-image: ${({ dataImage }) => `url(${APP_IMG}/${dataImage?.imagePathMobile})`};
  }
`;
const FooterWrapper = styled.div`
  &.footer-landing {
    position: fixed;
    left: 50%;
    bottom: 0;
    z-index: 999999999999;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 40px;
      max-width: 200px;
      margin: 20px auto;
      padding: 5px 25px;
      cursor: pointer !important;
      background: #fff;

      color: #000;
      font-size: 18px;
      border: none;
      border-radius: 50px;
      box-shadow: 0px 2px 3px #c3c3c3;
    }
  }
  @media (min-width: 767px) and (max-width: 900px) {
    .footer-landing {
      height: 200px;
    }
  }

  @media (max-width: 767px) {
    .footer-landing {
      justify-content: center;
      background: linear-gradient(to bottom, #ffffff00 0%, #ffffff 40%);
    }
    button {
      max-width: 150px;
      font-size: 16px;
      margin: 20px auto;
    }
    @media (max-width: 475px) {
      .footer-landing {
        background: linear-gradient(to bottom, #ffffff00 0%, #ffffff 40%);
      }
      button {
        max-width: 150px;
        font-size: 16px;
        margin: 20px auto;
      }
    }
  }
`;
export default SectionLandingPage;
